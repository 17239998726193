import React, { useState } from "react"
import "./Knowledgehub.css"
import { Content } from "../../components/UI"
import { LandingScreen } from "../Home/Styled"
import home_mobile from "../../images/home_mobile.jpg"
import BGIMG from "../../images/Landing_image.jpg"
import Signup from "../Home/signup"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { loginUrl } from "../../config/keys"
import hubImage from "../../images/Features1.jpg"
import BGIMG0 from "../../images/Home.jpg"
import features_mobile from "../../images/features_mobile.jpg"
import styled from "styled-components"

var hubdata = [
  {
    heading: "OverView",
    description:
      "Korero’s Knowledge Hub is a collection of digital artefacts – videos and articles that helps users get familiar with features and functionalities. Korero’s Knowledge Hub helps in guiding users especially small and mid-sized organizations to automate and streamline business processes.",
    description1:
      " An intuitive and minimalist User Interface with customization using rules in simple instructions ensures users can setup processes without any support. Designed to be highly adaptable, flexible, rich with features yet with a simple User Interface help achieve enterprises their objectives. ",
    description2:
      "Users can focus more on opportunity funnel and customer engagement than spending money and effort for customization. Topics listed below will help users get an impression of the interesting features.",
    id: "1",
  },
  {
    heading: "Aligning with Fiscal Year and Accounting Periods",
    description:
      "An enterprise reports its financial performance according to Accounting periods or Reporting periods so it becomes necessary to track opportunities closure, revenue generation and performance for the organization and also individual contribution.",
    description1:
      "Further for many companies the fiscal year may not align with the calendar year. For example, the fiscal year might end on May 31st rather than December 31st.  Accordingly the organization should also be able to configure the quarters according to their accounting period.",
    description2:
      "Korero allows an organization to customize their accounting period including the quarters, it provides you deeper insights into progress and performance. Easily configure your accounting period in less than a minute. Access reports based on accounting period easily, we further help you in analyzing performance based on historical data. Korero not only helps in Quarter on Quarter and Year on Year analysis but you can also track progress on a monthly and weekly level on a real time basis. No more waiting for the weekly or monthly report, access data on a rich dashboard sorted out with an extremely user friendly interface in real time.",
    description3: "",
    description4: "",
    ques1: "How to configure fiscal period ?",
    ques2: "Where do I review the fiscal period data ?",
    ans1:
      "Admin configures the accounting period during registration process which is simple and straightforward, estimated time to complete registration is less than 2 minutes.",
    ans2:
      "Admin has access to fiscal period data in the Settings menu / Organization option. However admin cannot change or modify fiscal time period, should you want to change then kindly reach out to the Korero support team.",
    id: "2",
  },
  {
    heading: "Multiple currency support – value to users",
    description:
      "As sales teams and companies operate in increasingly more countries/regions, it becomes necessary to manage sales opportunities and revenues in multiple regional currencies. The company wants to also report and analyze global sales in an unified currency which is its reporting currency.  ",
    description1: "",
    description2: "",
    description3:
      "All of these currency conversions and transactions are managed with in built processes so that the user need not spend time in administration tasks. All the data is showcased on rich dashboards in real time, enables you to spend more time with customer and work on the pursuit.",
    description4:
      "We currently support 4 currencies and their exchange conversions namely – USD, EUR, GBP and INR. We have plans to include key global currencies in our portfolio soon. ",
    subdescHeading: "Korero presents a lot of flexibility for an organization:",
    subdesc1:
      "Sales users can track their transactions in their local currency which allows them to manage their individual performances effectively",
    subdesc2:
      "Admin has access to organization wide data in a single reporting currency for easy reporting, analysis and more.",
    ques1: "How to configure reporting currency for organization ?",
    ques2: "Where do I review the organization currency information ?",
    ques3: "How can users check their own currency data ?",
    ans1:
      "Admin configures the reporting currency for the organization during registration process which is simple and straightforward. ",
    ans2:
      "Admin has access to currency data in the Settings menu / Organization option. However admin cannot change or modify organization currency, should you want to change then kindly reach out to the Korero support team.",
    ans3: "Users can access their currency data in the Profile section.",
    id: "3",
  },
  {
    id: "4",
    heading: "Setting up Workflows / Sales process",
    description:
      "Korero provides an out of box process to enable your users to start creating and manage opportunities across their life cycle.",
    description1:
      "Korero also allows you to create your own Workflows or sales processes (available in Professional subscription mode only) to better manage opportunities, for example different regions / industry sectors can have different processes and it becomes difficult to apply a single process.",
    description2:
      "Workflows can be accessed by the admin in the Settings  menu. In Professional version, admin can create multiple Workflows and populate each with its own stages. Further each stage can have multiple tasks too, Korero’s Task Manager presents a simple template to define Tasks for the organization, you can also create Approval process for each task. Define each task with an Executor and Approver, select their Functions (Korero supports multi functions – Sales, Finance,  Legal and Risk Management) and the job level to ensure governance. For each approval user setup, you can also set up when they receive notifications.",
    description3: "",
    subdescHeading1:
      "Walkthrough of Workflow setup (Professional subscription only)",
    subdesc: [
      "Include different functions if needed (Admin can access in Users menu)",
      "Create a Workflow and populate with Stages",
      "You can define Tasks for each Stage, easy to use Task creation template allows you to define the function and job level.",
      "Extend Task to Approval as needed also by defining Function and job level.",
      "Currently notifications are self-enabled, customization will be allowed in the near future",
    ],
  },
  {
    id: "5",
    heading: "Enable Value Adds and Roles",
    description:
      "Korero offers a number of Value Adds and Modules that significantly increase the level of engagement with your customer, keep a track of key opportunities and interactions, improve operations and more.",
    description1: (
      <span>
        For example Smart Boost
        <a
          className="fa fa-bolt"
          aria-hidden="true"
          style={{ fontSize: 30, margin: "0px 5px", color: "red" }}
        ></a>
        when enabled presents host of services like actionable insights so that
        you do not lose sight of the key opportunity and the necessary
        interactions with stakeholders.
      </span>
    ),
    description2:
      "QuotPro allows you to configure an offering of products and services and create a quote with the appropriate price. Manage the product master and connected pricing to ensure high quality and conformance of quote, communicate the quotation with your customer located across different geographies and currencies.",
    description3:
      "Similarly depending on operational requirements, you can enable modules for Legal, Finance and Risk Management primarily from a Task life cycle management, Approval management and Governance perspective.",
    description4:
      "Finally Viewport is a module that allows you to securely transfer key information on company performance between interested parties for example it will help an investor to track performance of invested companies, similarly that of subsidiaries for large enterprises. Rich dashboard presents data according to accounting periods in real time which enables immediate course correction if needed. Korero ensures high level of security, only an admin of an enterprise can share data with another admin provided both are Korero users and mutually agree to the data transfer.",
    description5: (
      <span>
        Each module and Value Add has its own pricing so please refer to the
        Value Adds section{" "}
        <Link to="/valueadds/">
          <u>http://www.tekorero.com/valueadds/</u>
        </Link>
        for more information on pricing and usage.
      </span>
    ),
    ques1: "Who can enable access to the various modules of Korero ?",
    ans1: (
      <span>
        Only the admin of your enterprise has access to enabling and disabling
        various modules and Value Adds. Do reach out to admin should you find
        the Value Adds interesting. Admin can reach out to the modules and Value
        Adds in the Users section on the menu bar and on clicking{" "}
        <a
          className="fa fa-heartbeat"
          aria-hidden="true"
          style={{ fontSize: 30, margin: "0px 5px", color: "red" }}
        ></a>
        can access these options.
      </span>
    ),
  },
]

function Knowledgehub() {
  const [hub, sethub] = useState(hubdata)
  const [currenthub, setcurrenthub] = useState(hubdata[0])
  const [currentId, setCurrentId] = useState("1")

  const handleClick = (item) => {
    setcurrenthub(item)
    setCurrentId(item.id)
  }
  console.log(currenthub)

  return (
    <div>
      <div className="mobile_text">
        Welcome to Korero’s{" "}
        <span style={{ color: "rgb(0, 191, 255)" }}>KNOWLEDGE HUB!</span>
      </div>
      <LandingScreen
      // backgroundImage={`url(${BGIMG0})`}
      // mobileBackgroundImage={`url(${home_mobile})`}
      >
        <div className="landing_image_main_box">
          <div className="landing_image_main_box_first_child">
            {/* <div className="image_text_For_landing_page"> */}
            <Box>
              <Header>Welcome to Korero’s KNOWLEDGE HUB!</Header>
              <br />
              <br />
              <Paragraph>
                Your CRM and Sales Automation platform designed for users to{" "}
                {/* <span style={{ color: "white" }}> */}
                start using without any training.
                {/* </span> */}
              </Paragraph>
              {/* </p> */}

              <Paragraph>
                Access Knowledge Hub, get started & close more opportunities.
              </Paragraph>
            </Box>
            {/* </div> */}

            <BoxLeftButton>
              <TrialLink1
                onClick={(e) => {
                  // To stop the page reloading
                  // e.preventDefault()
                  // Lets track that custom click
                  trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: "start your free trial  button in navbar ",
                    // string - required - Type of interaction (e.g. 'play')
                    action: "Click",
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: "Start your free trial button in navbar",
                    // number - optional - Numeric value associated with the event. (e.g. A product ID)
                    value: 43,
                  })
                  //... Other logic here
                }}
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                Start Free Trial
              </TrialLink1>
            </BoxLeftButton>
          </div>
        </div>
      </LandingScreen>
      <div className="landing-button_forMobile_feature">
        <a
          onClick={(e) => {
            trackCustomEvent({
              category: "start your free trial caraousel button ",

              action: "Click",

              label: "Start your free trial button in home page",

              value: 43,
            })
          }}
          className="banner_buttons"
          href={`${loginUrl}/register/Option`}
          target="_blank"
        >
          START YOUR 30 DAYS FREE TRIAL NOW!
        </a>
      </div>
      <div className="page_body1">
        <div className="hub-heading">{/* <Content></Content> */}</div>
        <div className="main_knowlede_hub_wrapper">
          <div className="Left_box">
            {hub.map((item) => {
              return (
                <>
                  <h4
                    style={{
                      cursor: "pointer",
                      color:
                        currentId === item.id ? "rgb(0, 191, 255)" : "black",
                    }}
                    onClick={() => handleClick(item)}
                  >
                    {item.heading}
                  </h4>
                </>
              )
            })}
          </div>
          <div className="vl" />
          <div className="right_box">
            <h4 className="sticky1">{currenthub.heading}</h4>
            <p className="content">{currenthub.description}</p>

            <p className="content">{currenthub.description1}</p>

            <p className="content">
              {currenthub.subdescHeading && <>{currenthub.subdescHeading}</>}
            </p>
            <p style={{ marginLeft: "40px" }} className="content">
              {currenthub.subdesc1 && (
                <>
                  <span style={{ fontSize: "22px" }}>1 . </span>
                  {` ${currenthub.subdesc1}`}
                </>
              )}
            </p>
            <p style={{ marginLeft: "40px" }} className="content">
              {currenthub.subdesc2 && (
                <>
                  <span style={{ fontSize: "22px" }}>2 . </span>
                  {` ${currenthub.subdesc2}`}
                </>
              )}
            </p>
            <p className="content">{currenthub.description2}</p>
            <p className="content">{currenthub.description3}</p>
            <p className="content">{currenthub.description4}</p>
            <p className="content">
              {currenthub.description5 && <>{currenthub.description5}</>}
            </p>
            <p className="content">
              {currenthub.subdescHeading1 && <>{currenthub.subdescHeading1}</>}
            </p>
            <p style={{ marginLeft: "40px" }} className="content">
              {currenthub.subdesc &&
                currenthub.subdesc.map((item) => {
                  return (
                    <ul>
                      <li>
                        <p className="content">{item}</p>
                      </li>
                    </ul>
                  )
                })}
            </p>
            <br />
            <p className="content1">{currenthub.ques1}</p>
            <p className="content">{currenthub.ans1}</p>
            <br />
            <p className="content1">{currenthub.ques2}</p>
            <p className="content">{currenthub.ans2}</p>
            <br />
            <p className="content1">{currenthub.ques3}</p>
            <p className="content">{currenthub.ans3}</p>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "130px" }}></div>
      <div className="container-3">
        <Signup />
      </div>
      {/* <div className="footer"></div> */}
    </div>
  )
}

export default Knowledgehub

const Box = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const BoxLeftButton = styled.div`
  /* padding: 20px 226px; */
  display: flex;
  justify-content: center;
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;

  background: #ee6352;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 1.5em;
  /* width: 148px;
  height: 58px; */
  color: #ffffff !important;
`
const Header = styled.h2`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 2.5em;
  text-align: center;
  letter-spacing: -0.5px;
  color: #080612;
  margin-left: 0.4375em;
`
const Paragraph = styled.p`
  font-family: "Roboto";
  font-size: 1.3em;
  text-align: left;
  color: #6b6a71;
  padding: 10px 60px;
  font-weight: 100;
`
