import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Knowledge_hub from "../modules/Knowledgehub/Knowledgehub"

const Knowledgehub = () => (
  <Layout>
    <SEO title="Knowledge Hub" 
    description="Korero’s Knowledge Hub is a collection of digital artefacts – videos and articles that helps
     users get familiar with features and functionalities. Korero’s Knowledge Hub helps in
     guiding users especially small and mid-sized organizations to automate and streamline business processes."/>
    <Knowledge_hub />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default Knowledgehub
